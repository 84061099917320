var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FeedbackProvider", {
    attrs: { errors: _vm.errors, "change-values": _vm.changeValues },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var hasError = ref.hasError
            var hasValueToSave = ref.hasValueToSave
            return [
              _c(
                "form",
                {
                  staticClass: "form",
                  style: _vm.styles,
                  attrs: { "data-cy": "form" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._t("header", function() {
                    return [
                      _vm.title
                        ? _c("h2", {
                            staticClass: "form__title",
                            domProps: { textContent: _vm._s(_vm.title) }
                          })
                        : _vm._e()
                    ]
                  }),
                  _vm._v(" "),
                  hasError
                    ? [
                        _c(
                          "section",
                          {
                            staticClass: "form__errors",
                            attrs: { "data-cy": "form-errors" }
                          },
                          [
                            _c("IconError", {
                              attrs: { "fill-color": _vm.redColor }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "errors-list" },
                              [
                                _c(
                                  "h3",
                                  { staticClass: "errors-list__header" },
                                  [
                                    _vm._v(
                                      "\n                            Please review errors below\n                        "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.presentationErrors, function(
                                  error,
                                  index
                                ) {
                                  return _c("LinkButton", {
                                    key: index,
                                    attrs: { title: error }
                                  })
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("Divider")
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("body", null, {
                    presentationErrors: _vm.presentationErrors
                  }),
                  _vm._v(" "),
                  _vm.isFooterVisible
                    ? _c(
                        "div",
                        { staticClass: "form__footer" },
                        [
                          _vm._t("submit", function() {
                            return [
                              _vm.isSubmitButtonVisible
                                ? _c("Button", {
                                    attrs: {
                                      "data-cy": "submit",
                                      title: _vm.submitTitle,
                                      disabled: _vm.disabled,
                                      type: "submit"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function(ref) {
                                            var color = ref.color
                                            return [
                                              _vm.isSubmitting
                                                ? _c("IconSpinner", {
                                                    attrs: {
                                                      "fill-color": color
                                                    }
                                                  })
                                                : hasValueToSave
                                                ? _c("IconSync", {
                                                    attrs: {
                                                      "fill-color": color
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _vm._e()
                            ]
                          }),
                          _vm._v(" "),
                          _vm._t("proceed", function() {
                            return [
                              _vm.isProceedButtonVisible
                                ? _c("Button", {
                                    attrs: {
                                      "data-cy": "proceed",
                                      title: _vm.proceedTitle,
                                      disabled: _vm.disabled,
                                      theme: _vm.secondaryTheme
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.onProceed.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm.isProceeding
                                          ? {
                                              key: "prepend",
                                              fn: function(ref) {
                                                var color = ref.color
                                                return [
                                                  _c("IconSpinner", {
                                                    attrs: {
                                                      "fill-color": color
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          : null
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }